<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        USP User Enrollment
      </v-expansion-panel-header>
      <v-expansion-panel-content>
            <div>

                <v-row>
                    <v-col :cols="12">
                        <v-btn
                            class="mx-5"
                            color="primary"
                            style="float:right;"
                            @click="openDialog"
                            >
                            <b>Add FLA</b>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <h5>To</h5>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                    <v-combobox
                            v-model="email_to"
                            hide-selected
                            multiple
                            persistent-hint
                            small-chips
                            height="20px"
                            class="px-5"
                            item-color="primary"
                        >
                        </v-combobox>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col :cols="1">
                        <h5>CC</h5>
                    </v-col>
                
                    <v-col :cols="5">
                    <v-combobox
                            v-model="email_cc"
                            hide-selected
                            multiple
                            persistent-hint
                            small-chips
                            item-color="primary"
                        >
                        </v-combobox>
                    </v-col>
                    <v-col :cols="1">
                        <h5>BCC</h5>
                    </v-col>

                    <v-col :cols="5">
                    <v-combobox
                            v-model="email_bcc"
                            hide-selected
                            multiple
                            persistent-hint
                            small-chips
                            item-color="primary"
                        >
                        </v-combobox>
                    </v-col>
                </v-row>
                
                <v-row>
                    <v-col :cols="12">
                        <v-data-table
                            :headers="headers"
                            :items="flaToEmail"
                            class="elevation-1"
                        >
                            <template v-slot:[`item.action`]="{index , item}">
                                <div>

                                    <v-btn
                                        text
                                        color="primary"
                                        @click="Delete(index , item)"
                                        >
                                        <v-icon>mdi-delete</v-icon>
                                        <!-- edit final draft encoder -->
                                    </v-btn>

                                </div>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </div>

            <v-row>
                <v-col>
                    <v-btn
                        class="mx-3"
                        color="primary"
                        style="float:right;"
                        @click="sendEmail"
                        >
                        <b>SEND EMAIL</b>
                    </v-btn>
                    <v-btn
                        class="mx-3"
                        color="primary"
                        style="float:right;"
                        outlined
                        @click="openPreview"
                        >
                        <b>PREVIEW</b>
                    </v-btn>
                </v-col>
            </v-row>
            

      </v-expansion-panel-content>

    </v-expansion-panel>

        <v-dialog v-model="dialog" persistent max-width="1300">
            <v-card class="pt-5">
                <v-card-text class="pt-5">
                    <v-data-table
                            :headers="flaHeaders"
                            :items="flaList"
                            class="elevation-1 pt-5"
                            :search = "search"
                        >
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <v-layout>
                                        <v-spacer></v-spacer>
                                        <v-flex md4 class="search">
                                            <v-text-field
                                                v-model="search"
                                                placeholder="Search"
                                                label="Search"
                                                single-line
                                                hide-details
                                            ></v-text-field> 
                                        </v-flex>
                                    </v-layout>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.action`]="{item, index}">
                                <div>

                                    <v-btn
                                        v-if="item.merchant_operator_status == 'Unenrolled'"
                                        text
                                        color="primary"
                                        @click="Add(item,index)"
                                        >
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>

                                </div>
                            </template>
                        </v-data-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="mx-3"
                        color="primary"
                        style="float:right;"
                        outlined
                        @click="dialog = false"
                        >
                        <b>Close</b>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog v-model="emailDialog" persistent max-width="1300">
            <v-card class="pt-5">
                <h2 class="px-5">Preview</h2>
                <!-- <h5 class="px-5 pt-5">Good Day IT!</h5>
                <h5 class="px-5 ">Please Process</h5>
                <h5 class="px-5 ">USP USER ENROLLMENT:</h5> -->
                <h5 class="px-5 pt-5">Good Day!</h5>
                <h5 class="px-5 pt-5">Please be informed that {{currUser.name}} has requested the processing of the following FLA for USP User Enrollment.</h5>
                <v-card-text class="pt-5">
                    <v-data-table
                        :headers="emailHeaders"
                        :items="flaToEmail"
                        class="elevation-1"
                    >
                    </v-data-table>
                </v-card-text>
                 <h5 class="px-5 pb-5">Thank you!</h5>
                 <h5 class="px-5 pb-5">Pera Remit Partner System Team</h5>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="mx-3"
                        color="primary"
                        style="float:right;"
                        outlined
                        @click="emailDialog = false"
                        >
                        <b>Close</b>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

  </v-expansion-panels>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
    data: () => ({
        search: '',
        headers: [
            {
            text: 'FLA (Name)',
            align: 'start',
            value: 'full_name',
            },
            { text: 'Branch', value: 'branch_name' },
            { text: 'Action', value: 'action' },
        ],
        emailHeaders: [
            {
            text: 'Date',
            align: 'start',
            value: 'date_today',
            },
            { text: 'Branch', value: 'branch_name' },
            { text: 'FLA (Name)', value: 'full_name' },
        ],
        flaToEmail: [],
        dialog : false,
        flaHeaders: [
            {
                text: 'FLA (Name)',
                align: 'start',
                value: 'full_name',
            },
            { text: 'Branch', value: 'branch_name' },
            { text: 'Status', value: 'merchant_operator_status' },
            { text: 'Action', value: 'action' },
        ],
        flaList: [],
        toAdd: [],
        email_cc: [],
        email_bcc: [],
        email_to: [],
        emailDialog: false,
    }),
    computed: {
    ...mapGetters({
        currUser: 'auth/currUser',
    })
  },
    methods:{
        Delete(id , value){
            this.flaToEmail.splice(id,1)
            let filteredArray = this.email_cc.filter(item => item !== value.email_address);
            this.email_cc = filteredArray
        },
        openPreview(){
            this.emailDialog = true
        },
        async openDialog(){
            const res = await this.$store.dispatch('merchantEnrollment/doGetFlaList', this.$route.params.id)
            if (res.status == 200){
                this.flaList = res.data.result
                this.dialog = true

                let uniqueArray1 = this.flaList.filter(item1 =>
                    this.flaToEmail.findIndex(item2 => item2.id === item1.id) === -1
                );

                this.flaList = uniqueArray1
            }
        },
        Add(item , id){

            const today = new Date();
            const day = today.getDate();
            const month = today.toLocaleString('default', { month: 'long' });

            const formattedDate = `${day}-${month}`;
            
            item.date_today = formattedDate
            this.flaToEmail.push(item)
            this.flaList.splice(id,1)

            this.email_cc.push(item.email_address)
       },
       async sendEmail(){

        if (this.flaToEmail.length == 0){
            this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Select FLA first!','messagetype':'error'});
        } else {
            const payload = {
                    id : this.$route.params.id,
                    finalPayload : {
                        to_email : this.email_to,
                        cc_email : this.email_cc,
                        bcc_email : this.email_bcc,
                        fla : this.flaToEmail
                    }
                }
                console.log(payload)
                const res = await this.$store.dispatch('merchantEnrollment/doSubmitMerchantEmail', {payload})
                if (res.status === 200){
                    console.log(res)
                    this.flaToEmail = []
                    this.email_bcc = []
                    this.email_cc = []
                    this.email_to = []
                    this.$store.dispatch('app/setSnackBar',{'status': true,'message': 'Submitted Successfully!' ,'messagetype':'success'});
                } else if (res.code === 400){
                    this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.error ,'messagetype':'error'});
                } else {
                    this.$store.dispatch('app/setSnackBar',{'status': true,'message': 'Error Occured!','messagetype':'error'});
                }
            }
       }
    }
}
</script>
