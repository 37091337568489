<template>

<div>
    <v-row>
        <v-col cols="12">
            <profile-card  />
        </v-col>
    </v-row>

    <v-row class="mx-5" v-if="currUser.is_onboarding_committee == '1'">
        <v-col :cols="6"></v-col>
        <v-col :cols="5">
            <v-file-input
                v-model="excel_file.file"
                :accept="acceptedFormats"
                prepend-icon="mdi-paperclip"
                label="Excel File"
                dense
                outlined
                hide-details="auto"
                >
            </v-file-input>
        </v-col>
        <v-col :cols="1">
            <v-btn
                v-if="excel_file.file"
                class="mr-4"
                outlined
                color="primary"
                @click="uploadExcel">
                Upload
            </v-btn>
            <v-btn
                v-else
                disabled
                class="mr-4"
                outlined
                color="primary">
                Upload
            </v-btn>
        </v-col>
    </v-row>

    <div class="px-5 py-5 my-5">
        <v-row>
            <v-col>
                <h4>Head Office Address</h4>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-text-field
                    label="*Address Line 1"
                    hide-details="auto"
                    outlined
                    v-model="primaryBusinessAddress.address_line_1"
                    dense
                    readonly
                    >
                </v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-text-field
                    label="*Address Line 2"
                    hide-details="auto"
                    outlined
                    v-model="primaryBusinessAddress.address_line_2"
                    dense
                    readonly
                    >
                </v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col
                cols="12"
                sm="6"
                md="2"
                >
                <v-text-field
                    label="*Country"
                    hide-details="auto"
                    outlined
                    item-text="country"
                    item-value="country"
                    v-model="primaryBusinessAddress.country"
                    dense
                    readonly
                    >
                </v-text-field>
            </v-col>

            <v-col
                cols="12"
                sm="6"
                md="3"
                >
                <v-text-field
                    label="*Province"
                    outlined
                    hide-details="auto"
                    v-model="primaryBusinessAddress.province"
                    dense
                    readonly
                    >
                </v-text-field>
            </v-col>

            <v-col
                cols="12"
                sm="6"
                md="2"
                >
                <v-text-field
                    label="*City"
                    outlined
                    hide-details="auto"
                    v-model="primaryBusinessAddress.city"
                    dense
                    readonly
                    >
                </v-text-field>
            </v-col>

            <v-col
                cols="12"
                sm="6"
                md="3"
                >
                <v-text-field
                    label="*Region"
                    outlined
                    hide-details="auto"
                    v-model="primaryBusinessAddress.region"
                    dense
                    readonly
                    >
                </v-text-field>
            </v-col>

            <v-col
                cols="12"
                sm="6"
                md="2"
                >
                <v-text-field
                    label="*Postal Code"
                    outlined
                    hide-details="auto"
                    v-model="primaryBusinessAddress.postal_code"
                    dense
                    type="number"
                    readonly
                    >
                </v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <h4>Contact Information</h4>
            </v-col>
        </v-row>
                            
        <v-row>
            <v-col
                cols="12"
                sm="12"
                md="6"
                >
                <v-text-field
                    label="Landline Number"
                    outlined
                    v-model="primaryBusinessAddress.landline"
                    hide-details="auto"
                    dense
                    type="number"
                    hide-spin-buttons
                    readonly
                    >
                </v-text-field>
            </v-col>
            
            <v-col
                cols="12"
                sm="12"
                md="6"
                >
                <v-text-field
                    label="*Cellphone Number"
                    outlined
                    v-model="primaryBusinessAddress.cellphone"
                    hide-details="auto"
                    dense
                    type="number"
                    hide-spin-buttons
                    readonly
                    >
                </v-text-field>
            </v-col>
        </v-row>
                            
        <v-row>
            <v-col
                cols="12"
                sm="12"
                md="6"
                >
                <v-text-field
                    label="*Designated Receiver"
                    outlined
                    v-model="primaryBusinessAddress.mailing_designated_receiver"
                    hide-details="auto"
                    dense
                    readonly
                    >
                </v-text-field>
            </v-col>
                                
            <v-col
                cols="12"
                sm="12"
                md="6"
                >
                <v-text-field
                    label="*Receiver Contact Number"
                    outlined
                    v-model="primaryBusinessAddress.mailing_contact_number"
                    hide-details="auto"
                    dense
                    type="number"
                    hide-spin-buttons
                    readonly
                    >
                </v-text-field>
            </v-col>
        </v-row>
    </div>

    <div class="px-5">
        <h4 v-if="unenrolledMerchantBranch.length != 0">Unenrolled Branch(s)</h4>
    </div>

    <v-expansion-panels class="px-5 pb-5 my-5">
        <v-expansion-panel v-for="(parentItem, parentIndex) in unenrolledMerchantBranch" :key="parentIndex" class="mb-3">
            <v-expansion-panel-header>
                    {{parentItem.branch_name}}
            </v-expansion-panel-header>
         <v-expansion-panel-content eager>
                        <div class="mt-1">

                            <v-row>
                                <v-col cols="6" v-if="parentItem.merchant_data">
                                    <v-text-field
                                        label="Account Number"
                                        hide-details="auto"
                                        v-model="parentItem.merchant_data.mer_account_number"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6" v-if="parentItem.merchant_data">
                                    <v-text-field
                                        label="Secondary Account"
                                        hide-details="auto"
                                        outlined
                                        v-model="parentItem.merchant_data.mer_secondary_account"
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                        label="Branch ID"
                                        hide-details="auto"
                                        v-model="parentItem.id"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        label="Terminal ID"
                                        hide-details="auto"
                                        v-model="parentItem.tid"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        label="Foreign Term ID"
                                        hide-details="auto"
                                        v-model="parentItem.ftid"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        label="Location Name"
                                        hide-details="auto"
                                        v-model="parentItem.branch_name"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Address Line 1"
                                        hide-details="auto"
                                        v-model="parentItem.address_line_1"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Address Line 2"
                                        hide-details="auto"
                                        v-model="parentItem.address_line_2"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                        label="City"
                                        hide-details="auto"
                                        v-model="parentItem.city"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        label="Province"
                                        hide-details="auto"
                                        v-model="parentItem.province"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        label="Postal Code"
                                        hide-details="auto"
                                        v-model="parentItem.postal_code"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Latitude"
                                        hide-details="auto"
                                        v-model="parentItem.coordinates.lat"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Longtitude"
                                        hide-details="auto"
                                        v-model="parentItem.coordinates.lng"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="2">
                                    <v-text-field
                                        label="Phone Country Code"
                                        hide-details="auto"
                                        value="+63"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="5">
                                    <v-text-field
                                        label="Phone Number"
                                        hide-details="auto"
                                        v-model="parentItem.landline"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="5">
                                    <v-text-field
                                        label="Class Code"
                                        hide-details="auto"
                                        v-model="parentItem.class_code_branch"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            

                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        label="Agent Type"
                                        hide-details="auto"
                                        v-model="agent_type"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                    <h4>Operation Hours</h4>
                                </v-col>
                                <v-col cols="12">
                                    <v-card elevation="0">
                                        <v-tabs
                                            v-model="tab"
                                            >
                                            <v-tab
                                                v-for="day in parentItem.operating_hours"
                                                :key="day.day"
                                                >
                                                <b>{{ day.day }}</b>
                                            </v-tab>
                                        </v-tabs>

                                        <v-tabs-items v-model="tab">
                                            <v-tab-item
                                                v-for="(day) in parentItem.operating_hours"
                                                :key="day.day"
                                                >
                                                <v-card flat>
                                                    <v-row class="my-3">
                                                        <v-col cols="4">
                                                            <v-text-field
                                                                label="Operating hours from"
                                                                hide-details="auto"
                                                                v-model="day.timeStart"
                                                                outlined
                                                                dense
                                                                readonly
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col cols="4">
                                                            <v-text-field
                                                                label="Operating hours to"
                                                                hide-details="auto"
                                                                v-model="day.timeEnd"
                                                                outlined
                                                                dense
                                                                readonly
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-card>
                                            </v-tab-item>
                                        </v-tabs-items>
                                    </v-card>
                                </v-col>
                            </v-row>

                            <v-row v-if="parentItem.merchant_data">
                                <v-col cols="12">
                                    <h4>Merchant Data</h4>
                                </v-col>
                            </v-row>

                            <v-row v-if="parentItem.merchant_data">
                                <v-col cols="12">
                                <v-simple-table
                                    fixed-header
                                    height="400px"
                                >
                                    <template v-slot:default>
                                    <thead>
                                        <tr>
                                        <th class="text-left">
                                            Item
                                        </th>
                                        <th class="text-left">
                                            Value
                                        </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                        v-for="(value, variable) in parentItem.merchant_data" :key="variable"
                                        >
                                        <td>{{ variable }}</td>
                                        <td>{{ value }}</td>
                                        </tr>
                                    </tbody>
                                    </template>
                                </v-simple-table>
                                </v-col>
                            </v-row>
                                
                        </div>

         </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>

    <v-pagination
    v-if="unenrolledMerchantBranch.length != 0"
    @input="changePage2"
    v-model="page2"
    :total-visible="5"
    :length="branchPage2.length"
    ></v-pagination>

    <div class="px-5">
        <h4 v-if="merchantBranch.length != 0">Enrolled Branch(s)</h4>
    </div>

    <v-expansion-panels class="px-5 pb-5 my-5">
        <v-expansion-panel v-for="(parentItem, parentIndex) in merchantBranch" :key="parentIndex" class="mb-3">
            <v-expansion-panel-header>
                    {{parentItem.branch_name}}
            </v-expansion-panel-header>
         <v-expansion-panel-content eager>
                        <div class="mt-1">

                            <v-row>
                                <v-col cols="6" v-if="parentItem.merchant_data">
                                    <v-text-field
                                        label="Account Number"
                                        hide-details="auto"
                                        v-model="parentItem.merchant_data.mer_account_number"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6" v-if="parentItem.merchant_data">
                                    <v-text-field
                                        label="Secondary Account"
                                        hide-details="auto"
                                        outlined
                                        v-model="parentItem.merchant_data.mer_secondary_account"
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                        label="Branch ID"
                                        hide-details="auto"
                                        v-model="parentItem.id"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        label="Terminal ID"
                                        hide-details="auto"
                                        v-model="parentItem.tid"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        label="Foreign Term ID"
                                        hide-details="auto"
                                        v-model="parentItem.ftid"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        label="Location Name"
                                        hide-details="auto"
                                        v-model="parentItem.branch_name"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Address Line 1"
                                        hide-details="auto"
                                        v-model="parentItem.address_line_1"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Address Line 2"
                                        hide-details="auto"
                                        v-model="parentItem.address_line_2"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                        label="City"
                                        hide-details="auto"
                                        v-model="parentItem.city"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        label="Province"
                                        hide-details="auto"
                                        v-model="parentItem.province"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        label="Postal Code"
                                        hide-details="auto"
                                        v-model="parentItem.postal_code"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Latitude"
                                        hide-details="auto"
                                        v-model="parentItem.coordinates.lat"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Longtitude"
                                        hide-details="auto"
                                        v-model="parentItem.coordinates.lng"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="2">
                                    <v-text-field
                                        label="Phone Country Code"
                                        hide-details="auto"
                                        value="+63"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="5">
                                    <v-text-field
                                        label="Phone Number"
                                        hide-details="auto"
                                        v-model="parentItem.landline"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="5">
                                    <v-text-field
                                        label="Class Code"
                                        hide-details="auto"
                                        v-model="parentItem.class_code_branch"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            

                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        label="Agent Type"
                                        hide-details="auto"
                                        v-model="agent_type"
                                        outlined
                                        dense
                                        readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                    <h4>Operation Hours</h4>
                                </v-col>
                                <v-col cols="12">
                                    <v-card elevation="0">
                                        <v-tabs
                                            v-model="tab"
                                            >
                                            <v-tab
                                                v-for="day in parentItem.operating_hours"
                                                :key="day.day"
                                                >
                                                <b>{{ day.day }}</b>
                                            </v-tab>
                                        </v-tabs>

                                        <v-tabs-items v-model="tab">
                                            <v-tab-item
                                                v-for="(day) in parentItem.operating_hours"
                                                :key="day.day"
                                                >
                                                <v-card flat>
                                                    <v-row class="my-3">
                                                        <v-col cols="4">
                                                            <v-text-field
                                                                label="Operating hours from"
                                                                hide-details="auto"
                                                                v-model="day.timeStart"
                                                                outlined
                                                                dense
                                                                readonly
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col cols="4">
                                                            <v-text-field
                                                                label="Operating hours to"
                                                                hide-details="auto"
                                                                v-model="day.timeEnd"
                                                                outlined
                                                                dense
                                                                readonly
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-card>
                                            </v-tab-item>
                                        </v-tabs-items>
                                    </v-card>
                                </v-col>
                            </v-row>

                            <v-row v-if="parentItem.merchant_data">
                                <v-col cols="12">
                                    <h4>Merchant Data</h4>
                                </v-col>
                            </v-row>

                            <v-row v-if="parentItem.merchant_data">
                                <v-col cols="12">
                                <v-simple-table
                                    fixed-header
                                    height="400px"
                                >
                                    <template v-slot:default>
                                    <thead>
                                        <tr>
                                        <th class="text-left">
                                            Item
                                        </th>
                                        <th class="text-left">
                                            Value
                                        </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                        v-for="(value, variable) in parentItem.merchant_data" :key="variable"
                                        >
                                        <td>{{ variable }}</td>
                                        <td>{{ value }}</td>
                                        </tr>
                                    </tbody>
                                    </template>
                                </v-simple-table>
                                </v-col>
                            </v-row>
                                
                        </div>

         </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>

        <v-pagination
        v-if="merchantBranch.length != 0"
        @input="changePage"
        v-model="page"
        :total-visible="5"
        :length="branchPage.length"
        ></v-pagination>

    <section v-if="currUser.is_onboarding_committee == '1'">
        
        <div class="px-5">
            <h4>Email FLA(s)</h4>
        </div>

        <section class="px-5 pb-5 my-5">
            <MerchantFla/>
        </section>

    </section>

        <v-row v-if="currUser.user_role == 'encoder'">
            <v-col>
                <v-btn
                    class="mx-5"
                    color="primary"
                    outlined
                    @click="goBack"
                    >
                    <b>Back</b>
                </v-btn>
                <v-btn
                    v-if="unenrolledMerchantBranch.length != 0 && merchant_status == 'New'"
                    class="mx-5"
                    color="success"
                    style="float:right;"
                    @click="encoderSend"
                    >
                    <b>Submit</b>
                </v-btn>
                <v-btn
                    v-if="unenrolledMerchantBranch.length != 0 && merchant_status == 'Done'"
                    class="mx-5"
                    color="success"
                    style="float:right;"
                    @click="encoderSend"
                    >
                    <b>Submit Unenrolled Branches</b>
                </v-btn>
            </v-col>
        </v-row>

        <v-row v-if="currUser.is_onboarding_committee == '1'">
            <v-col>
                <v-btn
                    class="mx-5"
                    color="primary"
                    outlined
                    @click="goBack"
                    >
                    <b>Back</b>
                </v-btn>
            </v-col>
        </v-row>

    </div>
    
    
    
</template>
<script>
import ProfileCard from '../../components/modules/salesProspecting/ProfileCard.vue';
import MerchantFla from './MerchantFLa.vue';
import _ from 'lodash';
import jsonToFormData from 'json-form-data';
import { mapGetters } from 'vuex';
export default {
    data: () => ({ 
        tab: null,
        page: 1,
		branchPage: [],
        page2: 1,
		branchPage2: [],
        agent_type: '',
        acceptedFormats: '.xlsx',
        merchant_status: '',
        branchNames: []
    }),
    components: {
		ProfileCard: ProfileCard,
        MerchantFla: MerchantFla
	},
    computed: {
        ...mapGetters({
            merchantBranch: 'merchantEnrollment/merchantBranch',
            unenrolledMerchantBranch: 'merchantEnrollment/unenrolledMerchantBranch',
            excel_file: 'merchantEnrollment/excel_file',
            currUser: 'auth/currUser',
            primaryBusinessAddress: 'salesProspecting/primaryBusinessAddress',
        })
    },
    async created () {
        this.reloadData()
    },
    methods: {
        async reloadData(){
            const resSP = await this.$store.dispatch('salesProspecting/doGetAllMerchantDetails', this.$route.params.id)
            this.assignData(resSP.data.result)
            this.merchant_status = resSP.data.result.merchant_status

            if (resSP.data.result.sp_other_primary_details){
                if (resSP.data.result.sp_other_primary_details.business_type_of_sub_agents){
                    this.newArr = JSON.parse(resSP.data.result.sp_other_primary_details.business_type_of_sub_agents)
                    this.agent_type = this.newArr.join(', ');
                }
            }

            this.reloadBranches()

        },
        async reloadBranches(){
            const resBranch = await this.$store.dispatch('salesProspecting/doGetEnrolledDetails', this.$route.params.id)
            let data = {
                resBranch : resBranch.data,
                inEnrolled : true
            }
            this.decodeBranches(data)

            let page_data = {
                page : resBranch.data.result,
                isEnrolled : true
            }
            this.countBranchPage(page_data)


            const res = await this.$store.dispatch('salesProspecting/doGetUnenrolledDetails', this.$route.params.id)
            let data2 = {
                resBranch : res.data,
                inEnrolled : false
            }
            this.decodeBranches(data2)

            let page_data2 = {
                page : res.data.result,
                isEnrolled : false
            }
            this.countBranchPage(page_data2)
        },
        countBranchPage(data){
            if (data.isEnrolled){
                let e = data.page
                this.page = 1
                this.branchPage = []
                if(e){
                    let number = e.last_page
                    for (let i = 1; i <= number; i++) {
                        this.branchPage.push(i)
                    }
                }
            }

            if (!data.isEnrolled){
                let e = data.page
                this.page2 = 1
                this.branchPage2 = []
                if(e){
                    let number = e.last_page
                    for (let i = 1; i <= number; i++) {
                        this.branchPage2.push(i)
                    }
                }
            }

		},
		async changePage(e){
			const payload = {
                id : this.$route.params.id,
                page: e
            }
			const res = await this.$store.dispatch('salesProspecting/changePageEnrolledBranch', {payload})
			if(res.status === 200) {
                let data = {
                    resBranch : res.data,
                    inEnrolled : true
                }
                this.decodeBranches(data)
			}
		},
		async changePage2(e){
			const payload = {
                id : this.$route.params.id,
                page: e
            }
			const res = await this.$store.dispatch('salesProspecting/changePageUnenrolledBranch', {payload})
			if(res.status === 200) {
				let data = {
                    resBranch : res.data,
                    inEnrolled : false
                }
                this.decodeBranches(data)
			}
		},
        decodeBranches(data){
            
            if (data.inEnrolled){

                let resBranches = data.resBranch.result.data

                resBranches.map(value =>{
                    this.branchNames.push(value.branch_name)
                })

                let expiryFile = {
                    file: null,
                    date_issued: '',
                    date_expiry: '',
                    menuIssued: false,
                    menuExpiry: false
                }
                resBranches.forEach(function (e) {
                    e.branch_id = e.id
                    e.businessPermitUploaded = JSON.parse(e.business_permit)
                    e.operating_hours = JSON.parse(e.operating_hours)
                    e.operating_hours.forEach(function (g) {
                        g.isClosed = parseInt(g.isClosed)
                    })
                    e.coordinates = JSON.parse(e.coordinates)
                    e.business_permit = _.clone(expiryFile)
                    e.fla.forEach(function (f) {
                        f.amlaUploaded  = JSON.parse(f.amla)
                        f.drugTestUploaded = JSON.parse(f.drug_test)
                        f.nbiUploaded = JSON.parse(f.nbi)
                        f.amla = _.clone(expiryFile)
                        f.drug_test = _.clone(expiryFile)
                        f.nbi = _.clone(expiryFile)
                    })
                })

                resBranches.map(item => {
                    item.operating_hours.map(data => {
                        if (data.timeStart){
                            const [timePart, amPm] = data.timeStart.split(' ');
                            const [hours, minutes] = timePart.split(':');
                            let militaryHours = parseInt(hours, 10);

                            if (amPm){
                                if (amPm.toUpperCase() == 'PM' && militaryHours !== 12) {
                                    militaryHours += 12;
                                }

                                if (amPm.toUpperCase() == 'AM' && militaryHours === 12) {
                                    militaryHours = 0;
                                }
                                const paddedHours = militaryHours.toString().padStart(2, '0');
                                const paddedMinutes = minutes.padStart(2, '0');
                                data.timeStart = `${paddedHours}:${paddedMinutes}`;
                            }    
                        }                
                    })

                    item.operating_hours.map(data => {
                        if (data.timeEnd){
                            const [timePart, amPm] = data.timeEnd.split(' ');
                            const [hours, minutes] = timePart.split(':');
                            let militaryHours = parseInt(hours, 10);

                            if (amPm){
                                if (amPm.toUpperCase() == 'PM' && militaryHours !== 12) {
                                    militaryHours += 12;
                                }

                                if (amPm.toUpperCase() == 'AM' && militaryHours === 12) {
                                    militaryHours = 0;
                                }
                                const paddedHours = militaryHours.toString().padStart(2, '0');
                                const paddedMinutes = minutes.padStart(2, '0');
                                data.timeEnd = `${paddedHours}:${paddedMinutes}`;
                            }  
                        }                  
                    })
                })

                this.$store.commit('merchantEnrollment/SET_MERCHANT_BRANCH', resBranches)
            }
            
            if (!data.inEnrolled){

                let resBranches = data.resBranch.result.data

                resBranches.map(value =>{
                    this.branchNames.push(value.branch_name)
                })

                let expiryFile = {
                    file: null,
                    date_issued: '',
                    date_expiry: '',
                    menuIssued: false,
                    menuExpiry: false
                }
                resBranches.forEach(function (e) {
                    e.branch_id = e.id
                    e.businessPermitUploaded = JSON.parse(e.business_permit)
                    e.operating_hours = JSON.parse(e.operating_hours)
                    e.operating_hours.forEach(function (g) {
                        g.isClosed = parseInt(g.isClosed)
                    })
                    e.coordinates = JSON.parse(e.coordinates)
                    e.business_permit = _.clone(expiryFile)
                    e.fla.forEach(function (f) {
                        f.amlaUploaded  = JSON.parse(f.amla)
                        f.drugTestUploaded = JSON.parse(f.drug_test)
                        f.nbiUploaded = JSON.parse(f.nbi)
                        f.amla = _.clone(expiryFile)
                        f.drug_test = _.clone(expiryFile)
                        f.nbi = _.clone(expiryFile)
                    })
                })

                resBranches.map(item => {
                    item.operating_hours.map(data => {
                        if (data.timeStart){
                            const [timePart, amPm] = data.timeStart.split(' ');
                            const [hours, minutes] = timePart.split(':');
                            let militaryHours = parseInt(hours, 10);

                            if (amPm){
                                if (amPm.toUpperCase() == 'PM' && militaryHours !== 12) {
                                    militaryHours += 12;
                                }

                                if (amPm.toUpperCase() == 'AM' && militaryHours === 12) {
                                    militaryHours = 0;
                                }
                                const paddedHours = militaryHours.toString().padStart(2, '0');
                                const paddedMinutes = minutes.padStart(2, '0');
                                data.timeStart = `${paddedHours}:${paddedMinutes}`;
                            }    
                        }                
                    })

                    item.operating_hours.map(data => {
                        if (data.timeEnd){
                            const [timePart, amPm] = data.timeEnd.split(' ');
                            const [hours, minutes] = timePart.split(':');
                            let militaryHours = parseInt(hours, 10);

                            if (amPm){
                                if (amPm.toUpperCase() == 'PM' && militaryHours !== 12) {
                                    militaryHours += 12;
                                }

                                if (amPm.toUpperCase() == 'AM' && militaryHours === 12) {
                                    militaryHours = 0;
                                }
                                const paddedHours = militaryHours.toString().padStart(2, '0');
                                const paddedMinutes = minutes.padStart(2, '0');
                                data.timeEnd = `${paddedHours}:${paddedMinutes}`;
                            }  
                        }                  
                    })
                })

                this.$store.commit('merchantEnrollment/SET_UNENROLLED_MERCHANT_BRANCH', resBranches)
            }
        },
        assignData (data) {
			this.status = data.status
			var details = {}
			details.created_at  = data.created_at
			details.profile = data.profile
			details.status = data.status
			details.account_name = data.account_name
            this.$store.commit('salesProspecting/SET_PRIMARY_DETAILS', details)
            if (data.sp_other_primary_details){
                details.entity_type = data.sp_other_primary_details.type
            }
            if (data.sp_primary_business_address) {
				this.$store.commit('salesProspecting/SET_PRIMARY_BUSINESS_ADDRESS', data.sp_primary_business_address)
			}
		},
        goBack(){
            history.back();
        },
        async encoderSend(){
            const res = await this.$store.dispatch('merchantEnrollment/doSubmitToOnboarding', this.$route.params.id)
            if (res.status === 200){
                console.log(res)
                history.back();
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': 'Submitted Successfully','messagetype':'success'});
            } else if (res.status === 206 || res.code === 400 || res.code === 422){
                console.log(res)
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.error,'messagetype':'error'});
            }
        },
        async uploadExcel(){
            const initialPayload = this.excel_file
            var options = {
				initialFormData: new FormData(),
				howLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			}
			var convertedFormData = jsonToFormData(initialPayload, options)
			const payload = {
                    payload: convertedFormData,
                    id: this.$route.params.id
                }
            const res = await this.$store.dispatch('merchantEnrollment/doUploadExcel', {payload})
            console.log(res)
            if (res.status == 200){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': 'Uploaded Successfully','messagetype':'success'});
                this.$store.commit('merchantEnrollment/SET_MERCHANT_DEFAULT')
                this.reloadData()
            } else if (res.status === 206 || res.code === 400 || res.code === 422){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.error,'messagetype':'error'});
                this.$store.commit('merchantEnrollment/SET_MERCHANT_DEFAULT')
            } else {
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': 'Error Occured!','messagetype':'error'});
            }
        },
    }
}
</script>
<style>
</style>